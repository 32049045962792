// extracted by mini-css-extract-plugin
export var container = "people-module--container--biTcq";
export var subtitle = "people-module--subtitle--bNjSJ";
export var memberContainer = "people-module--member-container--+UtF8";
export var member = "people-module--member--jHQ-M";
export var profileContainer = "people-module--profile-container--6nKTn";
export var name = "people-module--name--aweh7";
export var position = "people-module--position--qf+85";
export var modalContainer = "people-module--modal-container--XI7PB";
export var modal = "people-module--modal--jeF4c";
export var closeBtn = "people-module--close-btn--EMhNh";
export var modalTextContainer = "people-module--modal-text-container--xeJB0";
export var modalTitle = "people-module--modal-title--GzWBQ";
export var modalText = "people-module--modalText--XXO9X";